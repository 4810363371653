$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-navy: #050a2d;
$color-pink: #d1487c;
$color-teal: #68dec1;
$color-gray: #f7f7f7;

$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;
$hamburger-layer-height: 6px;
$hamburger-layer-width: 40px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-border-radius: 0px;
$hamburger-layer-color: $color-navy;
$hamburger-active-hover-opacity: 1;
