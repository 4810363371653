@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers";

html {
	background: $color-white;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-navy;
}

a {
	color: $color-navy;

	@include hover-focus {
		color: $color-teal;
	}
}

.container {
	max-width: 1230px !important;
}

header {
	position: relative;
	z-index: 1030;

	&:before {
		background: linear-gradient(119deg, rgba(109, 207, 193, 0.96) 0%, rgba(129, 148, 191, 0.97) 28%, rgba(187, 81, 146, 0.97) 68%, rgba(227, 90, 98, 0.98) 100%);
		content: '';
		display: block;
		height: rem(8);
	}

	.container {
		padding-bottom: rem(50);
		padding-top: rem(50);

		@include desktop-sm-down {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			padding-bottom: rem(30);
			padding-top: rem(30);
		}

		> img {
			display: none;

			@include desktop-sm-down {
				display: block;
				max-width: rem(100);
			}
		}

		> #nav-collapse {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			@include desktop-sm-down {
				display: none;

				&.open {
					background: $color-white;
					display: block;
					left: 0;
					padding: rem(30);
					position: absolute;
					right: 0;
					text-align: center;
					top: 100%;

					h1 {
						display: none;
					}
				}
			}

			> nav {
				flex: 0 1 calc(50% - #{rem(150) });

				> ul {
					list-style: none;
					margin: 0;
					padding: 0;

					> li {
						display: inline-block;
						font-size: rem(16);
						font-weight: 700;
						letter-spacing: letter-spacing(200);
						text-indent: text-indent(200);
						text-transform: uppercase;
						vertical-align: middle;

						@include desktop-sm-down {
							display: block;
						}

						+ li {
							margin-left: rem(50);

							@include desktop-sm-down {
								margin-left: 0;
								margin-top: rem(20);
							}
						}
					}
				}
			}

			> h1 {
				flex: 0 0 rem(300);
				margin: 0;
				max-width: rem(300);
				position: relative;

				> div {
					position: absolute;
					top: rem(-60);

					> img {
						margin: 0 auto;
					}

					> span {
						display: block;
						font-size: rem(14);
						margin-top: rem(15);
						text-align: center;
						text-transform: uppercase;
					}
				}
			}

			> .header-contact {
				flex: 0 1 calc(50% - #{rem(150) });
				text-align: right;

				@include desktop-sm-down {
					margin-top: rem(30);
					text-align: center;
				}

				> ul {
					list-style: none;
					margin: 0 0 rem(10) 0;
					padding: 0;

					> li {
						display: inline-block;
						font-size: rem(20);
						vertical-align: middle;

						+ li {
							margin-left: rem(15);
						}
					}
				}

				p {
					font-size: rem(14);
					margin: 0;
				}
			}
		}

		.hamburger {
			display: none;

			@include desktop-sm-down {
				display: inline-block;
			}
		}
	}
}

main {
	.gradient-text {
		background: linear-gradient(119deg, rgba(109, 207, 193, 0.96) 0%, rgba(129, 148, 191, 0.97) 28%, rgba(187, 81, 146, 0.97) 68%, rgba(227, 90, 98, 0.98) 100%);
		-webkit-background-clip: text;
		font-size: rem(50);
		font-weight: 900;
		line-height: 1;
		margin: 0;
		-webkit-text-fill-color: transparent;
		text-transform: uppercase;
	}

	#intro {
		position: relative;

		> img {
			width: 100%;
		}

		.container {
			bottom: rem(100);
			left: 0;
			position: absolute;
			right: 0;
			text-align: center;

			> h2 {
				color: $color-white;
				font-size: rem(24);
				font-weight: 700;
				letter-spacing: letter-spacing(500);
				line-height: line-height(36, 24);
				margin: 0;
				text-indent: text-indent(500);
				text-transform: uppercase;
			}
		}
	}

	#solution {
		padding: rem(100) 0;

		.container {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			@include tablet-down {
				display: block;
			}

			> img {
				flex: 0 0 auto;
				max-width: 50%;

				@include tablet-down {
					margin: 0 auto rem(30) auto;
					max-width: 100%;
				}
			}

			> div {
				flex: 1 1 auto;
				padding-left: rem(90);

				@include desktop-sm-down {
					padding-left: rem(30);
				}

				@include tablet-down {
					padding-left: 0;
					text-align: center;
				}

				h2 {
					font-size: rem(26);
					font-weight: 900;
					margin: 0 0 rem(40) 0;
					text-transform: uppercase;

					> span {
						display: block;

						+ span {
							margin-top: rem(10);
						}
					}
				}

				p {
					font-size: rem(16);
					line-height: line-height(25, 16);
					margin: 0;

					+ p {
						margin-top: rem(25);
					}

					&.pink {
						color: $color-pink;
						font-size: rem(18);
						font-weight: 900;
					}
				}
			}
		}
	}

	#about {
		background: $color-navy;
		color: $color-white;
		padding: rem(60) 0 rem(100) 0;
		position: relative;

		@include desktop-sm-down {
			padding-bottom: 0;
		}

		.container {
			> div {
				max-width: rem(500);
				position: relative;
				z-index: 2;

				@include desktop-sm-down {
					max-width: 100%;
					text-align: center;
				}

				> h2 {
					font-size: rem(50);
					font-weight: 900;
					line-height: 1;
					text-transform: uppercase;

					> img {
						display: inline-block;
						vertical-align: bottom;
					}
				}

				> p {
					font-size: rem(16);
					line-height: line-height(26, 16);
					margin: 0;

					+ p {
						margin-top: rem(25);
					}

					&.teal {
						color: $color-teal;
					}
				}
			}

			> img {
				bottom: 0;
				position: absolute;
				right: 0;
				z-index: 1;

				@include desktop-sm-down {
					margin-right: -15px;
					margin-top: rem(30);
					position: relative;
					right: -15px;
				}
			}
		}
	}

	#services {
		padding: rem(100) 0 0 0;

		.gradient-text {
			text-align: center;
		}

		.services {
			> div {
				padding: rem(70);

				> .container {
					align-items: center;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;

					@include tablet-down {
						display: block;
						text-align: center;
					}

					> img {
						flex: 0 1 auto;
						margin-right: rem(40);

						@include tablet-down {
							margin-bottom: rem(30);
							margin-left: auto;
							margin-right: auto;
						}
					}

					> div {
						flex: 1 1 auto;
						font-size: rem(18);
						line-height: line-height(25, 18);

						p {
							margin: 0;

							+ p {
								margin-top: rem(25);
							}
						}
					}
				}


				&:nth-child(even) {
					background: $color-gray;

					> .container {
						> img {
							margin-left: rem(40);
							margin-right: 0;
							order: 2;

							@include tablet-down {
								margin-bottom: rem(30);
								margin-left: auto;
								margin-right: auto;
							}
						}
					}
				}
			}
		}
	}

	#contact {
		background: url('../img/bg/contact-bg.png') no-repeat center top;
		background-size: cover;
		padding: rem(100) 0;

		.contact-box {
			background: $color-white;
			padding: rem(60);
			text-align: center;

			> h2 {
				font-size: rem(54);
				font-weight: 900;
				line-height: 1;
				margin: 0 0 rem(30) 0;
				text-transform: uppercase;
			}

			> a {
				background: $color-teal;
				color: $color-white;
				display: inline-block;
				font-size: rem(20);
				font-weight: 900;
				margin: rem(20) 0;
				padding: rem(20) rem(60);
				text-transform: uppercase;
				vertical-align: middle;

				@include hover-focus {
					background: $color-pink;
					color: $color-white;
				}
			}

			> p {
				font-size: rem(18);
				line-height: line-height(25, 18);
				margin: 0 auto;
				max-width: rem(500);

				+ p {
					margin-top: rem(20);
				}
			}
		}
	}
}

footer {
	padding: rem(30) 0;

	.container {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;

		@include phone-down {
			display: block;
		}

		> img {
			max-width: rem(170);

			@include phone-down {
				margin: 0 auto;
			}
		}

		> .copyright {
			text-align: right;

			@include phone-down {
				margin-top: rem(20);
				text-align: center;
			}

			> ul {
				list-style: none;
				margin: 0 0 rem(10) 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(20);
					vertical-align: middle;

					+ li {
						margin-left: rem(15);
					}
				}
			}

			> p {
				font-size: rem(12);
				letter-spacing: letter-spacing(200);
				line-height: line-height(18, 12);
				margin: 0;
				text-indent: text-indent(200);
				text-transform: uppercase;
			}
		}
	}
}
